import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAppSelector } from 'hooks/reduxHooks';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { getApplicationStatus } from 'thunks';
import { CommonTranslationKeys, TranslationNameSpaces } from 'enums/Translation';
import { getOffersData } from 'handlers/selectors';
import Loading from 'components/Loading';
import Title from 'components/Title';
import ClientEventsApi from 'api/digifi/portal/ClientEventsApi';
import styles from './WaitingExperience.module.scss';
import { LocalStorageConstants, LocalStorageKeys } from 'enums/LocalStorage';

const WaitingExperience = () => {
  const { t: translate } = useTranslation(TranslationNameSpaces.Common);
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const { applicationId } = useParams();
  const { offers } = useAppSelector(getOffersData);

  const LOAN_OFFERS_CONNECT_URL = '/api/loan-offers/connect';
  
  useEffect(() => {
    if (!applicationId) return;
    
    const clientEventsApi = new ClientEventsApi(async () => {
      console.log({offers})
      if (!offers) {
        await dispatchWithUnwrap(getApplicationStatus(applicationId));
    
        localStorage.setItem(LocalStorageKeys.isOffersGenerate, LocalStorageConstants.FALSE);
      }
    }, `${LOAN_OFFERS_CONNECT_URL}/${applicationId}`);

    return () => {
      clientEventsApi.close();
    };
  }, []);

  return (
    <div className={styles.waitingWrapper}>
      <Title variant="h2" marginBottom="24">
        {translate(CommonTranslationKeys.WaitingExperienceTitle)}
      </Title>
      <Loading />
    </div>
  );
};

export default WaitingExperience;
