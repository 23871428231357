export default class ClientEventsApi {
    private eventSource: EventSource;
    private handler: (event?: MessageEvent<any>) => void;
  
    constructor(handler: (event?: MessageEvent<any>) => void, url: string) {
      this.handler = handler;
      this.eventSource = new EventSource(url);
      this.eventSource.onmessage = this.handleMessage;
    }
  
    private handleMessage = async (event: MessageEvent<any>) => {
      console.log({event})
      await this.handler(event);
    };
  
    public close() {
      this.eventSource.close();
    }
  }
